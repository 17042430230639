.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(-60deg, #aeb8b0, #67846d, #666b67, #414542);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.dashboard-container .version {
  color: white;
  font-style: italic;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.logo {
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.buttons > * {
  margin-top: 10px;
}

.project-list {
  max-height: 400px;
  overflow: auto;
  margin-top: 10px;
}

.project-list-item:hover {
  cursor: pointer;
  background: #f0f0f0;
  text-indent: 10px;
  transition: all ease 0.6s;
}
