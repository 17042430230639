.project-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 26px;
  padding-top: 25px;
}

.meta-container {
}

.canvas-container {
  display: block;
  background: #e8e8e8;
  outline: #515151 2px solid;
}

.toolbar-container {
  padding: 10px 10px;
  background: #515151;
  width: 100%;
  color: white;
}

.toolbar-container button {
  margin-right: 5px;
}

.log-container {
  padding: 5px 10px;
  font-size: 12px;
  background: #515151;
  color: white;
  width: 100%;
}

.infos {
  display: flex;
}

.info-container {
  margin: 0px 40px 10px 0px;
}

.info-title {
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.info-value {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  box-sizing: border-box;
}
