.empty-container {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-title {
  padding-top: 30px;
  font-size: 14px;
  font-weight: bold;
}

.empty-body {
  font-size: 12px;
}

.version-title {
  margin-left: 10px;
  opacity: 0.5;
}

.versions-container {
  display: block;
  width: 100%;
  max-height: 60vh;
  overflow: auto;
}

.version-number {
  font-weight: bold;
  font-size: 11px;
}

.version-descriptions {
  margin-top: 10px !important;
}

.version-description {
  font-size: 12px;
  margin: 0;
  margin-bottom: 2px;
}

.version-warning {
  padding: 3px 6px !important;
  margin-bottom: 20px !important;
  font-size: 12px !important;
  font-weight: bold;
  color: #777777 !important;
}
